.modal{
    background-color: rgba(0, 0, 0, 0.2);
    display: grid;
    position: fixed;
    width: calc(100vw - clamp(215px, 26%, 335px));
    height: calc(100vh - 59px);
    top: 59px;
    left: clamp(215px, 26%, 335px);
    z-index: 1;
    align-content: center;
    .modal-content{
        background: #ffffff;
        margin: 10%;
        padding: 15px 25px;
        overflow: auto;
        border-radius: 6px;
        display: grid;
        grid-template-rows: auto 1fr auto;
        .modal-content--header{
            display: flex;
            align-items: center;
            padding-bottom: 12px;
            justify-content: end;
            .search-input{
                flex: 1;
                margin-right: 100px;
                input{
                    border-radius: 20px;
                }
            }
            .icon-close{
                margin: 0;
            }
        }
        .modal-content--body{
            overflow: auto;
        }
        .modal-content--footer{
            padding: 15px 0 0 0;
            display: flex;
            justify-content: end;
            gap: 30px;
        }
        input,
        .select-input,
        .select-list,
        input[type='radio'] + .label-mask:after,
        input[type='checkbox'] + .label-mask:after{
            border: 1px solid #ebebeb;
        }
        input[type='radio'] + .label-mask:before{
            top: 5px;
            left: 5px;
        }
    }
    &.size-xl{
        .modal-content{
            margin: 10%;
        }
    }
    &.size-l{
        .modal-content{
            margin: 10% 17%;
        }
    }
    &.size-m{
        .modal-content{
            margin: 10% 24%;
        }
    }
    &.size-s{
        .modal-content{
            margin: 10% 31%;
        }
    }
    &.size-xs{
        .modal-content{
            margin: auto auto;
            max-width: 280px;
            max-height: 85vh;
        }
    }

    &.size-standar{
        .modal-content{
            margin: auto auto;
        }
    }
}

@media (max-width: 900px) {
    .modal{
        width: 100vw;
        left: 0;
        .modal-content{
            margin: 10%;
        }
    }
}