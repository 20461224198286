.home{
    .home-section{
        display: flex;
        margin-top: 80px;
        flex-wrap: wrap;
        >*{
            &:nth-child(odd){
                width: 55%;
                margin-right: 25px;
            }
            &:nth-child(even){
                flex: 1;
                margin-left: 25px;
            }
        }
        .donwload{
            width: 100%;
            text-align: center;
            margin-top: 30px;
        }
    }
    .create-account{
        padding: 10px 15%;
    }
}

@media (max-width: 830px) {
    .home{
        .home-section{
            margin-top: 0;
            >*{
                &:nth-child(odd){
                    width: 100%;
                    margin-right: 0;
                }
                &:nth-child(even){
                    margin-left: 0;
                    margin-top: 15px;
                }
            }
        }
    }
}

@media (max-width: 575px) {
    .home{
        .create-account{
            padding: 10px 3%;
        }
    }
 }