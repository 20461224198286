.userAcounts{
    padding-right: 10px;
    .acount-title{
        font-size: 20px;
        label{
            font-size: 24px;
            color: $text-cta-color;
            padding-right: 5px;
        }
    }
    .acount-delete{
        color: $primary-color;
        display: flex;
        cursor: pointer;
    }
    .acount-select-row{
        align-items: center;
    }
    .accounts-module{
        background-color: $background-color;
        width: 100%;
        padding: 2px 24px 12px 24px;
        h4{
            font-weight: 700;
            padding: 10px 0  8px 0;
        }
        .accounts-module--container{
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr;
        }
        .module-container {
            margin: 1px 20px 1px 0;
        }
        .module{
            background: transparent;
            height: 24px;
            width: 24px;
            margin-right: 3px;
        }
        p{
            font-size: 14px;
        }
    }
    .accounts-module.disabled{
        .module{
            filter: grayscale(1);
        }
    }
}