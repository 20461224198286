.input-file{
    display: flex;
    width: 100%;
    input[type=file]{
        width: 0;
        height: 0;
        position:absolute;
        opacity: 0;
    }
    .container-file{
        width: 100%;
        background-color: #ffffff;
        margin: 0 13px;
        padding: 7px 12px 7px 12px;
        border-radius: 4px;
        overflow: hidden;
        p{
            width: 100%;
            font-weight: 400;
            letter-spacing: normal;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            display: inline-block;
        }
        &.placeholder{
            p{
                opacity: 0.3;
            }
        }
    }
}