.dropdown{
    background-color: #fff;
    width: 180px;
   
    .dropdown-icon-container{
       background-color:transparent;
       position: relative;
       text-align: right !important;
    }
    .dropdown-menu{
        background-color: #fff;
        margin-top: -20px;
        height: 90px;
        width: 180px;
        border:1px solid #e9dede;
        border-radius: 4px;
    }
    .dropdown-li{
         padding: 15px ;
       
         text-align: center;
    }
    .dropdown-li:hover{
        background-color: #f8f8f8 ;
        
    }
        
    
}