.module{
    border-radius: 50%;
    background-color: #cccccc;
    display: grid;
    margin-right: 10px;
    height: 26px;
    width: 26px;
    justify-content: center;
    align-content: center;
    &.active{
        background-color:  $secondary-color;
    }
}
.module-container{
    display: flex;
    margin: 8px 20px 8px 0;
    cursor: pointer;
}
