.paginator{
    display: flex;
    margin:  10px 0;
    justify-content: end;
    .page{
        padding: 3px 0px 4px 0px;
        border-radius: 50%;
        box-sizing: border-box;
        text-align: center;
        &.page-action{
            width: 23px;
            cursor: pointer;
            &:hover{
                background: $background-color;
            }
        }
        &.page-tag{
            color: $secondary-color;
        }
        &.page-active{
            background: $secondary-color;
            color: #ffffff;
            &:hover{
                background: $secondary-color;
            }
        }
        &.page-length{
            margin: 0 5px;
        }
    }

}