.new-account{
   .create-account{
      padding: 10px 15%;
   }
   .accounts{
      .body-row{
         border-bottom: none
     }
   }
}

@media (max-width: 575px) {
   .new-account{
       .create-account{
           padding: 10px 3%;
       }
   }
}