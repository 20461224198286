.radio-or-check{
    display: flex;
    align-items: center;
    padding: 5px 45px 5px 0;
    position: relative;
    label{
        vertical-align: middle;
        padding-right: 20px;

    }
    input:not(:checked), input:checked {
        position: absolute;
        opacity: 0;
        bottom: 0;
        height: 0;
        width: 0;
    }
    input + .label-mask {
        position: relative;
        cursor: pointer;
        display: inline-block;
        font-weight: 400;
        height: 20px;
        width: 20px;
        padding-right: 0;
        top: 2px;

    }
    input + .label-mask:before, input + .label-mask:after {
        content: '';
        left: 0;
        position: absolute;
        transition: border .25s,background-color .25s,width .2s .1s,height .2s .1s,top .2s .1s,left .2s .1s;
        z-index: 1;
    }
    input + .label-mask:after {
        height: 20px;
        width: 20px;
        z-index: 0;
    }
    input:not(:checked) + .label-mask:before {
        width: 0;
        height: 0;
    }
    input[disabled] + .label-mask::after{
        background-color: #e2e6e8;
    }


    input[type='radio'] + .label-mask:after, input[type='radio'] + .label-mask:before {
        border-radius: 50%;
    }
    input[type='radio']:not(:checked) + .label-mask:before {
        left: 15px;
        top: 15px;
    }
    input[type='radio']:checked + .label-mask:before {
        top: 4px;
        left: 4px;
        width: 12px;
        height: 12px;

    }
    input[type='radio'] + .label-mask:after {
        background-color: #ffffff
    }

    input[type='radio']:checked + .label-mask:before {
        background-color: $primary-color
    }


  
    input[type='checkbox'] + .label-mask:after {
        border-radius: 6px;
        background-color: #ffffff
    }
    input[type='checkbox']:not(:checked) + .label-mask:before {
        left: 3px;
        top: 10px;
        transform: rotateZ(37deg);
        transform-origin: 100% 100%;
    }
    input[type='checkbox']:checked + .label-mask:before {
        top: 1px;
        left: 0px;
        width: 3px;
        height: 8px;
        border-top: 3px solid transparent;
        border-left: 3px solid transparent;
        border-right: 3px solid  $primary-color;
        border-bottom: 3px solid  $primary-color;
        transform: rotateZ(37deg);
        transform-origin: 100% 100%;
    }
}

@media (max-width: 405px) {
    .radio-or-check{
        padding: 5px 38px 5px 0;
    }
}