.confirm {
    &-price-content {
    padding: 20px;
    }

    &-actions-row{
        display: flex;
        justify-content: flex-end;
    }
    &-primary-btn{
        background-color: transparent;
        color: $primary-color;
        text-decoration-line: underline;
        margin-right: 40px;
    }

}



