.load-file{
    padding: 10px 3%;
    p{
        p{
            display: inline;
        }
    }
}

@media (max-width: 880px) {
    .load-file{
        overflow-wrap: anywhere;
        .form-actions-row{
            flex-wrap: wrap;
            .input-file{
                margin-bottom: 15px;
                > button{
                    margin-left: 0;
                    white-space: nowrap;
                }
                .container-file{
                    margin-right: 0;
                }
            }
        }
    }
}