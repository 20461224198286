.loader{
    position:fixed;
    width: 100vw;
    height: 100vh;
    display: grid;
    justify-content: center;
    align-content: center;
    background-color: rgba(0,0,0,0.8);
    z-index: 1;
    h3{
        color: #ffffff;
    }
    .spinner-box {
        width: 150px;
        height: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        margin: 0 auto;
    }
    .circle-border {
        width: 100px;
        height: 100px;
        padding: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background: rgb(0, 194, 202);
        background: linear-gradient(0deg, rgba(51,51,51,0.1) 33%, rgba(0, 194, 202, 1) 100%);
        animation: spin 1s linear 0s infinite;
    }
    
    .circle-core {
        width: 100%;
        height: 100%;
        background-color: #333333;
        border-radius: 50%;
    }
    /* KEYFRAMES */

    @keyframes spin {
        from {
        transform: rotate(0);
        }
        to{
        transform: rotate(360deg);
        }
    }
}