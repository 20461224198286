.accordion{
    width: 100%;
    .accordion-header{
        display: flex;
        align-items: end;
        margin-bottom: 22px;
        cursor: pointer;
        h4{
            padding: 0 10px;
            font-weight: 800;
            flex: 1;
        }
        .search-input{
            min-width: 400px;
        }
    }
}