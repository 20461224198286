.menu{
    width: 26%;
    max-width: 335px;
    min-width: 215px;
    .logo{
        height: 59px;
        background: $text-color;
        position: fixed;
        width: 335px;
        z-index: 2;
        img{
            height: 100%;
            padding: 23px 36px;
            box-sizing: border-box;
        }
        .icon{
            margin: 15px;
            display: none;
        }
    }
    nav{
        padding-top: 59px;
        min-height: calc(100vh - 59px);
        background: $background-color;
        display: inline-block;
        width: 100%;
        box-shadow: 0 calc(100vh - 59px) 0 0 $background-color, 0 calc((100vh - 59px)*2) 0 0 $background-color, 0 calc((100vh - 59px)*3) 0 0 $background-color;
        .menu-select{
            padding: 15px 11px;
            .select-input{
                background-color: #cccccc;
                &.selected{
                    background-color: #CAF3D1;
                }
            }
        }
        .menu-input{
            padding: 5px 11px;
            position: relative;
            .icon{
                position: absolute;
                top:12px;
                left: 13px;
            }
            input{
                width: 100%;
                padding: 3px 12px 6px 40px;
                border-radius: 16px;
                box-sizing: border-box;
            }
        }
        ul{
            list-style: none;
            margin: 0;
            padding: 0;
            li{
                list-style: none;
                font-weight: 600;
                font-size: 16px;
                line-height: 23px;
                letter-spacing: 0.15px;
                .nav-item{
                    padding: 17px 27px 13px 27px;
                    display: flex;
                    cursor: pointer;
                    position: relative;
                    &.active{
                        background-color: #CAF3D1;
                        &::after{
                            content: "";
                            position: absolute;
                            width: 4px;
                            height: 100%;
                            left: 0;
                            top: 0;
                            background-color: $primary-color;
                        }
                        .nav-icon{
                            background-color: $primary-color;
                        }
                    }
                    .nav-icon{
                        width: 26px;
                        height: 26px;
                        display: inline-grid;
                        background-color: $text-color;
                        border-radius: 50%;
                        justify-content: center;
                        align-content: center;
                        margin-right: 16px;
                    }
                }
                
                ul{
                    li{
                        font-weight: 500;
                        padding: 11px 16% 11px 25%;
                        cursor: pointer;
                        position: relative;
                        &:hover{
                            background-color: $background-color;
                            mix-blend-mode:multiply
                        }
                        &.active{
                            background-color: $background-color;
                            mix-blend-mode:multiply;
                            &::before{
                                content: "";
                                position: absolute;
                                width: 4px;
                                height: 100%;
                                right: 0;
                                top: 0;
                                background: $interactions-gradient;
                            }
                        }
                    }
                }
                &:hover{
                    .nav-item{
                        //background-color: opacify($primary-color,0.2)
                        background-color: #CAF3D1;
                    }
                }
            }
        }
    }
}

@media (max-width: 900px) {
    .menu{
        width: 0;
        max-width: 100vw;
        position: fixed;
        z-index: 3;
        max-height: 100vh;
        overflow: auto;
        .logo{
            width: 56px;
            img{
                display: none;
            }
            .icon-nav{
                display: inline-block;
            }
        }
        nav{
            display: none;
        }

        &.menu-active{
            width: 100vw;
            background: rgba($color: #000000, $alpha: 0.3);
            .logo{
                width: calc(216px + 56px);
                img{
                    display: inline-block;
                }
                .icon-close-white{
                    display: inline-block;
                    vertical-align: super;
                }
                .icon-nav{
                    display: none;
                }
            }
            nav{
                width: calc(216px + 56px);
                display: inline-block;
            }
        }
    }
}