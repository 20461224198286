    .tabs-container{
        display: flex;
        flex-direction: column;
    }
    .tabs-bloc-tabs{
        display: flex;
    }

    .tabs-tabs{
        padding: 15px;
        text-align: center;
        width: 50%;
        background-color: #fff;
        cursor: pointer;
        border-bottom: 1px solid rgba(0, 0, 0, 0.274);
        box-sizing: content-box;
        position: relative;
        outline: none;
        color: black;
    }

    .tabs-tabs:hover{
        background-color: $background-color
    }

    .tabs-active-tabs  {
        background: white;
        font-size: 17px;
        border-bottom: 1px solid transparent;
    }

    .tabs-active-tabs:hover  {
        background: white;
    }
      
    .tabs-active-tabs::before {
        content: "";
        display: flex;
        position: absolute;
        top: 49px;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% + 2px);
        height: 5px;
        background: #52C7CF;
    }
      
      .tabs-button {
        border: none;
    }
      .tabs-content-tabs {
        flex-grow : 1;
    }
    .tabs-content {
        background: white;
        padding-top: 20px;
        width: 100%;
        height: 100%;
        display: none;
    }
    .tabs-content h2 {
        padding: 0px 0 5px 0px;
    }
    .tabs-content hr {
        width: 100px;
        height: 2px;
        background: #222;
        margin-bottom: 5px;
    }
    .tabs-content p {
        width: 100%;
        height: 100%;
        color: #36C457;
        display: inline;
    }
    .tabs-active-content {
        display: block;
    }
    .tabs-container-download{
        display: flex;
        align-items: center;
        cursor: pointer;
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .tabs-container-info{
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .tabs-content-info{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        column-gap: 20px;
        row-gap: 20px;
        padding-top: 20px;
        padding-bottom: 40px;
        width: 450px;

        label{
            width: 120px;
            color:#5E5E5E;
        }
    }

    .tabs-container-imp{
        width: 450px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        label{
            color:#AAAAAA;
        }
    }
    .tabs-content-info-title{
        label{
            color:#5E5E5E;
        }
    }

