header{
    background: $background-color;
    height: 59px;
    display: flex;
    align-items: center;
    padding: 0 38px;
    width: calc(100% - min(26%, 335px) - 56px);
    z-index: 2;
    position: fixed;
    .user{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex: 1;
        .user-data{
            margin: 0 16px;
            .role{
                font-size: 10px;
                line-height: 16px;
                letter-spacing: 2px;
                text-transform: uppercase;
            }
        }
        .photo{
            width: 34px;
            height: 34px;
            border-radius: 50%;
            background: $primary-color;
        }
    }
}
@media (max-width: 900px) {
    header{
        width: calc(100% - 56px - 56px);
        margin-left: 56px;
    }
}

@media (max-width: 465px) {
    header{
        width: calc(100% - 56px - 20px);
        padding: 0 10px;
    }
}