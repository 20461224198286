*{
    margin: 0;
    padding: 0;
}
html, body, div, span, applet, object, iframe,h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,b, u, i, center,dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    font: inherit;
    vertical-align: baseline;
}
audio, canvas, progress, video {
    display: inline-block;
}
audio:not([controls]) {
    display: none;
    height: 0;
}
/* Address `[hidden]` styling not present in IE 8/9/10.
 * Hide the `template` element in IE 8/9/11, Safari, and Firefox < 22.*/
[hidden], template {
    display: none;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
    display: block;
}
body {
    line-height: 1;
}
ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
a {
    color:rgb(0,0,0);
}
dfn {
    font-style: italic;
}
/*img {
    border: 0;
    width 100%
}*/
/* Correct overflow not hidden in IE 9/10/11.*/

svg:not(:root) {
    overflow: hidden;
}
button{
    cursor: pointer;
}
button, input, optgroup, select, textarea {
    color: inherit;
    font: inherit;
    margin: 0;
}
button[disabled], html input[disabled] {
    cursor: default;
}
