.accounts{
    margin: 0 0 30px 0;
    .name{
        overflow-wrap: anywhere;
        img{
            width: 24px;
            height: 24px;
            border-radius: 50%;
            margin-right: 16px;
            vertical-align: middle;
            background-color: $primary-color;
        }
    }
    .user-modules{
        display: flex;
    }
    .copy{
        display: flex;
        p{
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            display: inline-block;
            font-size: 12px;
        }
        .icon{
            min-width: 16px;
        }
    }
}


@media (max-width: 705px) {
    .accounts{
        .name img{
            display: none;
        }
    }
}