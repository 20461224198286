.load-category-tree{
    padding: 10px 3%;
}

@media (max-width: 460px) {
    .load-category-tree{
        .actions--inline{
            .input-and-action{
                flex-wrap: wrap;
                justify-content: end;
                button{
                    margin-top: 15px;
                }
            }
        }
    }
}