.back{
    margin: 15px 0;
    font-size: 20px;
    cursor: pointer;
    float: right;
    position: relative;
    z-index: 1;
    .icon{
        vertical-align: text-top;
    }
}