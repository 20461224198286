$dirname:'http://localhost:3000';

$primary-color: #41D561;
$secondary-color: #52C7CF;
$text-color: #001305;
$text-secondary-color: #EAFBEE;
$text-cta-color: #7B7B7B;
$background-color: #F5F5F5;

$link-color: #5288CF;
$succes-color: #00C483;
$error-color: #D04E3C;

$text-stroke: linear-gradient(180deg, rgba(20, 20, 20, 0.5) 0%, rgba(20, 20, 20, 0.02) 100%);
$interactions-gradient: linear-gradient(139.83deg, #80E593 3.8%, #52C7CF 82.11%);

body{
    font-family: 'Be Vietnam', sans-serif;
}

h1{
    font-weight: 500;
    font-size: 48px;
    line-height: 52px;
}
h2{
    font-weight: 600;
    font-size: 34px;
    line-height: 49.7px;
    letter-spacing: 0.25px;
}
h3{
    font-weight: 500;
    font-size: 24px;
    line-height: 35.1px;
}
h4{
    font-weight: 500;
    font-size: 16px;
    line-height: 24.5px;
    letter-spacing: 0.15px;
}
h5{
    font-weight: 500;
    font-size: 20px;
    line-height: 29.2px;
    letter-spacing: 0.15px;
}

p{
    font-weight: 500;
    font-size: 16px;
    line-height: 23.4px;
    letter-spacing: 0.15px;
    margin: 0;
}
b{
    font-weight: 900
}
span{
    font-weight: 500;
    font-size: 14px;
    line-height: 20.5px;
    letter-spacing: 0.4px;
}

caption{
    font-weight: 500;
    font-size: 12px;
    line-height: 17.5px;
    letter-spacing: 0.4px;
}

.overline{
    font-weight: 700;
    font-size: 10px;
    line-height: 14.6px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

