form, .form{
    label{
        flex: 1;
        padding-right: 25px;
        font-weight: 700;
        &.right-aline{
            text-align: right;
        }
    }
    .form-row{
        padding: 10px 0;
        display: flex;
        align-items: baseline;
        input, textarea, .radios{
            flex: 3;
        }
        .select{
            flex: 3.1;
        }
    }
    .form-actions-row{
        padding: 10px 0;
        display: flex;
        justify-content: right;
        margin-bottom: 10px;
        &.actions--inline{
            align-items: baseline;
            .input-and-action{
                flex: 3.1;
                display: flex;
                align-items: center;
                > input, textarea, .select, .react-datepicker-wrapper{
                    width:  100%;
                    margin-right: 13px;
                    &:last-child{
                        margin-right: 0;
                    }
                }
               
            }
            &.more-label{
                label{
                    flex: 1.65;
                }
            }
        }
        button{
            margin-left: 15px;
            &:first-child{
                margin-left: 0px;
            }
        }
    }
    .form-title{
        margin-bottom: 30px;
        p{
            padding-top: 20px;
            color: #425046;
        }
    }
    .radios{
        display: flex;
        width: 100%;
        flex-wrap: wrap;
    }
    .modules{
        display: flex;
        width: 73%;
        flex-wrap: wrap;
    }
}