.page-title{
    padding: 12px 22px;
    position: relative;
    margin-bottom: 14px;
    h3{
        font-size: 26px;
        font-weight: 600;
    }
    &::before{
        content: "";
        width: 3px;
        height: calc(100% - 29px);
        left: 0;
        position: absolute;
        background: $interactions-gradient;
        margin-top: 4px;
    }
}