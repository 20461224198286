.app{
    display: flex;
    align-items: stretch;
    align-content: flex-start;
    width: 100%;
    .app-container{
        flex: 1;
        width: 100%;
        .app-content{
            margin-top: 59px;
            padding: 7% 6%;
        }
    }
}