input{
    padding: 5px 12px 9px 12px;
    border: none;
    border-radius: 4px;
    background-color: #ffffff;
    width: 100%;
    &:disabled{
        opacity: 0.5;
        cursor: not-allowed;
    }
    &.border{
        border: 1px solid #ebebeb;
    }
}

.search-input{
    position: relative;
    .icon{
        position: absolute;
        top: 10px;
        left: 5px;
    }
    input{
        padding-left: 30px;
        box-sizing: border-box;
    }
}