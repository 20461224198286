.new-user{
   .add-user{
        padding: 10px 24px;
        .form-actions-row{
            align-items: center;
            button{
                white-space: nowrap;
            }
        }
        .modal-content--header{
            margin-bottom: 12px;
            border-bottom: 1px solid #BDBDBD;
        }
   }
   .users{
        .header-cell{
            text-align: center;
        }
        .header-cell:first-child{
            text-align: left;
        }
        .user-modules {
            justify-content: center;
        }
   }
}

@media (max-width: 575px) {
    .new-user{
        .add-user{
            padding: 10px 3%;
        }
    }
}