.select{
    width: 100%;
    position: relative;
    .select-input{
        display: flex;
        align-items: center;
        padding: 9px 10px 9px 15px;
        border: none;
        border-radius: 4px;
        background-color: #ffffff;
        .input-box{
            flex: 1;
            min-height: 19px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            display: inline-block;
            &.placeholder{
                opacity: 0.5;
            }
        }
    }
    .select-list{
        position: absolute;
        border-radius: 4px;
        background-color: #ffffff;
        width: 100%;
        top: 42px;
        z-index: 2;
        overflow: auto;
        max-height: 400px;
        li{
            padding: 8px 12px;
            cursor: pointer;
            &:hover, &.active{
                background-color: $text-secondary-color;
            }
            &.search-input{
                .icon{
                    top: 17px;
                    left: 14px;
                }
                input{
                    padding: 0px 12px 4px 20px;
                }
            }
            &.emty{
                cursor: context-menu;
                opacity: 0.5;
            }
            &.disabled{
                opacity: 0.5;
            }
        }
    }
}