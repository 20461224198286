.files-scheduled{
    margin: 30px 0;
    .file{
        text-decoration: underline;
    }
    .large{
        overflow-wrap: anywhere;
    }
    p{
        font-weight: 400;
    }
    .table .body-row .body-cell {
        font-size: 16px;
        padding: 12px 10px;
        h4{
            opacity: 0.6;
        }
        &:last-child{
            align-self: center;
        }
    }
    .moreInfo{
        display: flex;
        > div{
            padding: 12px 10px;
        }
        h4{
            opacity: 0.6;
        }
        .table-actions{
            align-self: center;
        }
    }
    .no-results{
        padding: 30px 0;
        font-size: 18px;
        font-weight: 500;
        opacity: 0.5;
    }
}