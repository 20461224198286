.drag-container{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
    padding-top: 10px;
    

    .drag-upload-wrap{
        position: relative;
        height: 231px;
        width: 610px;
        border: 2px solid #d0d7de;
        border-radius: 4px;
        margin-top: 6px;
        background-position: center;
        background-repeat: no-repeat  !important;
      
    }

    .file-upload-input {
        margin: 0;
        padding: 0;
        width: 100%;
        height: 200px;
        outline: none;
        opacity: 0;
        cursor: pointer;
    }
    
    .file-upload-content {
      display: none;
      text-align: center;
    }

    .drag-upload-wrap:hover {
      background-color: transparent;
      border: 2px dashed #d0d7de;
    }

    .text-information {
      position: absolute;
      text-align: center;
      top: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
    }

    .drag-upload-file-ctn{
      display: flex;
      justify-content: center;
      height: 231px;
      width: 490px;
      border-radius: 4px;
      border: 1px solid grey;
    }

    .drag-upload-file{
      display: flex;
      justify-content: space-between;
      border: 1px solid grey;
      width: 390px;
      height: 56px;
      border-radius: 4px;
      margin-top: 20px;
      padding: 16px 24px;
    }

   

}

.drag-btn-container {
  display: flex;
  justify-content: flex-end;
}

.drag-upload{
  position: relative;
  height: 231px;
  width: 610px;
  border: 2px solid #d0d7de;
}