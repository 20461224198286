button{
    font-weight: 700;
    font-size: 14px;
    line-height: 20.5px;
    letter-spacing: 1px;
    padding: 9px 13px;
    border-radius: 3px;
    background-color: $text-color;
    color: #ffffff;
    border:none;
    &.secondary-btn{
        background-color: $primary-color;
        padding: 8px 20px;
    }
    &.link-btn{
        background-color: transparent;
        color: $primary-color;
        text-decoration: underline;
    }
    &[disabled]{
        opacity: 0.3;
    }
}