.login{
    background: $text-color;
    width: 100vw;
    height: 100vh;
    display: grid;
    justify-content: center;
    align-content: center;
    .container{
        width: 432px;
        background-color: $background-color;
        border-radius: 20px;
        overflow: hidden;
        .login-cover{
            width: 100%;
            height: 50vh;
            max-height: 390px;
            background: #141414;
            //border-radius: 20px 20px 0 0;
            display: grid;
            justify-content: center;
            align-content: center;
            padding: 0 50px;
            box-sizing: border-box;
            img{
                width: 100%;
            }
        }
        .login-action{
            padding: 35px;
            h5{
                margin-bottom: 16px;
            }
            button{
                width: 100%;
                box-shadow: 0px 10px 10px rgba(20, 20, 20, 0.05) !important;
                border-radius: 10px !important;
                span{
                    width: calc(100% - 96px);
                    font-family: 'Be Vietnam', sans-serif;
                }
            }
            #signInDiv > div{
                display: flex;
                justify-content: center;
            }
        }
    }
}

@media (max-width: 435px) {
    .login{
        .container{
            width: 95vw;   
            #signInDiv > div{
                left: -8px;
            }
        }
    }
}