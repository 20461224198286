.card-container{
    border-radius: 4px;
    border: 1px solid #CCCCCC;
    width: calc((100% - 16%) /2);
    margin-bottom: 40px;
    align-items: center;
    height: 110px;
    padding: 12px 16px 32px 24px;
    flex: none; 
  

    .card-subtitle{
        display: flex;
        height: 90px;
        justify-content: space-between;
    }

    .card-label{
        display: flex;
        padding-top: 20px;
        justify-content: space-between;
    }
    .card-label-1{
        color: #979797
    }

    .card-icon:hover{
        color: green;
        cursor: pointer;
    }

    .card-button-active{
        background-color: $primary-color;
     
        border-radius: 20px;
        color: white;
        padding: 3px;
        font-size: 10px;
    }

    .card-button-inactive{
        background-color: black;
        
        border-radius: 20px;
        color: white;
        padding: 3px;
        font-size: 10px;
    }
}
