.users{
    margin: 0 0 30px 0;
    .name{
        overflow-wrap: anywhere;
        img{
            width: 24px;
            height: 24px;
            border-radius: 50%;
            margin-right: 16px;
            vertical-align: middle;
            background-color: $primary-color;
        }
    }
    .user-modules{
        display: flex;
    }
    .table{
        .body-row{
            .body-cell:nth-child(2){
                text-align: center;
            }
        }
    }
}

@media (max-width: 1180px) {
    .users{
        .table{
            .header-row{
                .header-cell:first-child{
                    flex: 4 0 0% !important;
                }
            }
            .body-row{
                .body-cell:first-child{
                    flex: 4 0 0% !important;
                }
            }
        }
    }
}

@media (max-width: 545px) {
    .users{
        .table .body-row .body-cell:last-child{
            flex: 1.7 0 0 !important;
        }
        .table .header-row .header-cell:last-child {
            flex: 1.7 0 0 !important;
        }
    }
}

@media (max-width: 510px) {
    .users{
        .name img{
            display: none;
        }
    }
}