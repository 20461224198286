.table{
    width: 100%;
    .header-row{
        display: flex;
        .header-cell{
            padding: 22px 10px;
            font-size: 14px;
            font-weight: 700;
        }
    }
    .body-row{
        display: flex;
        align-items: center;
        .body-cell{
            padding: 9px 10px;
            font-size: 12px;
            width: 0;
        }
        .more{
            display: none;
        }
        &.expand{
            flex-wrap: wrap;
            .more{
                display: inline-block;
                width: 100%;
            }
        }
        &:hover{
            background-color: $background-color;
            //mix-blend-mode:multiply
        }
    }
    &.action{
        .body-row{
            cursor: pointer;
        }
    }
    &.all-border{
        border: 1px solid $secondary-color;
        border-radius: 8px;
        .body-row{
            align-items: stretch;
            border-top: 1px solid $secondary-color;
            .body-cell{
                border-right: 1px solid $secondary-color;
                &:last-child{
                    border-right: none;
                }
            }
        }
        .header-cell{
            border-right: 1px solid $secondary-color;
            &:last-child{
                border-right: none;
            }
        }
    }
    &.lower-border{
        .body-row{
            border-bottom: 1px solid $secondary-color;
        }
        .header-row{
            border-bottom: 2px solid rgba($color: $text-cta-color, $alpha: 0.5);
        }
    }
    &.card-type{
        .body-row{
            border: 1px solid $secondary-color;
            border-radius: 4px;
            margin: 16px 0;
        }
        .header-row{
            height: 8px;
        }
    }

    .table-actions{
        text-align: center;
    }
}