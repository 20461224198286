.recent-accounts{
    .name{
        img{
            width: 24px;
            height: 24px;
            border-radius: 50%;
            margin-right: 16px;
            vertical-align: middle;
        }
    }
    .body-row{
        &:nth-child(3n - 1){
            .name{
                img{
                    background-color: $primary-color;
                }
            }
        }
        &:nth-child(3n){
            .name{
                img{
                    background-color: $secondary-color;
                }
            }
        }
        &:nth-child(3n +1){
            .name{
                img{
                    background-color: #F0E159;
                }
            }
        }
    }
    .moreInfo{
        width: calc(100% - 20px);
        margin: 5px 10px;
        box-sizing: border-box;
        padding: 10px 20px;
        background-color: #ffffff;
        font-size: 12px;
        border-radius: 3px;
        position: relative;
        p{
            font-size: 12px;
            color: $secondary-color;
            padding-bottom: 10px;
            overflow-wrap: anywhere;
        }
        &::before{
            content: "";
            position: absolute;
            left: 8px;
            top: -20px;
            border-bottom: 10px solid #ffffff;
            border-top: 10px solid transparent;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
        }
    }
}