.icon{
    width: 29px;
    height: 29px;
    margin: 0 5px;
    display: inline-block;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    &.icon-xs{
        width: 12px;
        height: 12px;
    }
    &.icon-s{
        width: 16px;
        height: 16px;
    }
    &.icon-m{
        width: 20px;
        height: 20px;
    }
    &.icon-l{
        width: 26px;
        height: 26px;
    }
    &.icon-xl{
        width: 32px;
        height: 32px;
    }

    &.icon-action{
        cursor: pointer;
    }

    &.icon-disabled{
        opacity: 0.5;
        filter: grayscale(1);
        cursor: context-menu;
    }

    &.icon-home{
        background-image: url( '../images/icons/home.svg' );
    }
    &.icon-u{
        background-image: url( '../images/icons/U.svg' );
    }
    &.icon-exit{
        background-image: url( '../images/icons/exit.svg' );
    }
    &.icon-create{
        background-image: url( '../images/icons/create.svg' );
    }
    &.icon-edit{
        background-image: url( '../images/icons/edit.svg' );
    }
    &.icon-new{
        background-image: url( '../images/icons/new.svg' );
    }
    &.icon-replicate{
        background-image: url( '../images/icons/replicate.svg' );
    }
    &.icon-config{
        background-image: url( '../images/icons/config.svg' );
    }
    &.icon-arrow-up{
        background-image: url( '../images/icons/arrow.svg' );
        transform: rotate(180deg);
    }
    &.icon-arrow-down{
        background-image: url( '../images/icons/arrow.svg' );
    }
    &.icon-delete-color{
        background-image: url( '../images/icons/delete-color.svg' );
    }
    &.icon-star-color{
        background-image: url( '../images/icons/star-color.svg' );
    }
    &.icon-edit-color{
        background-image: url( '../images/icons/edit-color.svg' );
    }
    &.icon-import-color{
        background-image: url( '../images/icons/import-color.svg' );
    }
    &.icon-back{
        background-image: url( '../images/icons/back.svg' );
    }
    &.icon-search{
        background-image: url( '../images/icons/search.svg' );
    }
    &.icon-add-color{
        background-image: url( '../images/icons/add-color.svg' );
    }
    &.icon-copy{
        background-image: url( '../images/icons/copy.svg' );
    }
    &.icon-nav{
        background-image: url( '../images/icons/nav.svg' );
    }
    &.icon-close{
        background-image: url( '../images/icons/close.svg' );
    }
    &.icon-close-white{
        background-image: url( '../images/icons/close-white.svg' );
    }
    &.icon-price{
        background-image: url( '../images/icons/price.svg' );
    }
    &.icon-inventory{
        background-image: url( '../images/icons/inventory.svg' );
    }
    &.icon-alert{
        background-image: url( '../images/icons/alert.svg' );
    }
    &.icon-menu-inventory{
        background-image: url('../images/icons/menu-inventory.svg');
    }
   
    &.icon-download-color{
        background-image: url('../images/icons/download-color.svg');
    }

    &.icon-check{
        background-image: url('../images/icons/check-not.svg');
    }
    &.icon-check.active{
        background-image: url('../images/icons/check.svg');
    }
}

    .drag-upload-wrap{
        background-image: url('../images/icons/drag.svg');
    }